import "core-js/modules/es.array.push.js";
export default {
  name: "HomeMain",
  data() {
    // 产品描述列表
    this.productsList = [{
      title: "有渠道",
      desc1: "商家专属的直销订房平台",
      desc2: '自有客户在官方渠道预订"0"佣金'
    }, {
      title: "有工具",
      desc1: "免费的酒店民宿PMS管理系统",
      desc2: "简单便捷，容易上手"
    }, {
      title: "会分销",
      desc1: "官渠产品同步在合作商家官渠展示",
      desc2: "成千上万家酒店为您分销"
    }, {
      title: "会运营",
      desc1: "专业代运营团队提供业内经营指导 ",
      desc2: "行业活动或峰会邀请名额"
    }];
    // 合作价值列表
    this.collaborativeList = [{
      title: "自有预订平台",
      desc1: "官渠预订0佣金"
    }, {
      title: "分销按量付费",
      desc1: "无订单不收费"
    }, {
      title: "资源独立专属",
      desc1: "保障私域会员"
    }, {
      title: "小商家大平台",
      desc1: "实现规模优势"
    }];
    return {};
  },
  methods: {
    goRegister() {
      this.$router.push("/register");
    }
  }
};