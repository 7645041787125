import { BaseDialogWx } from "@/components";
export default {
  components: {
    BaseDialogWx
  },
  methods: {
    goRegister() {
      this.$refs.baseDialogWx.show("申请试用");
    }
  }
};